import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';

const Container = styled.div`
  width: 100%;
  max-width: 300px;
`;

const SearchInput = styled.input`
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${$.color.gray};
  font-size: 16px;
  width: calc(100% - 20px - 2px);
  outline: none;
`;

const Search = ({ setSearchTerm }) => (
  <Container>
    <SearchInput
      onChange={(e) => {
        setSearchTerm(e.target.value);
      }}
      placeholder="Search student/instructor"
    />
  </Container>
);

Search.defaultProps = {
  setSearchTerm: () => {},
};

Search.propTypes = {
  setSearchTerm: PropTypes.func,
};

export default Search;
