import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { addAttendanceGQL } from '../../../apollo/mutations';
import $ from '../../../styles/global';

const Container = styled.div`
  overflow: scroll;

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    width: 100%;
  }

  // #endregion
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid ${$.color.gray};

  thead {
    background-color: ${$.color.gray};

    tr {
      td {
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
        border-right: 1px solid ${$.color.black};

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 10px;
        border-bottom: 1px solid ${$.color.gray};
      }
    }
  }
`;

const SessionTypeDropdown = styled.select`
  ${({ status }) => `
  min-width: 90px;
  border-radius: 5px;
  display: block;
  margin: auto;
  padding: 5px;
  text-align: center;
  background-color: ${status === 'online' ? $.color.blue4 : '#c91d1d'};
  color: ${$.color.white};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  border: none;
  outline: none;
  margin-bottom: 2px;
`}
`;

const Pill = styled.div`
  background-color: rgba(17, 64, 96, 0.2);
  font-size: 14px;
  color: ${$.color.blue4};
  padding: 5px 10px;
  border-radius: 15px;
  letter-spacing: 0.5px;
  text-align: center;
  font-weight: bold;
  max-width: 80px;
  margin: auto;
`;

const VerifiedPill = styled(Pill)`
  background-color: rgba(8, 150, 63, 0.2);
  color: ${$.color.green};
`;

const PendingPill = styled(Pill)`
  background-color: rgba(166, 166, 2, 0.2);
  color: #a6a602;
`;

const StudentInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > div {
    &:first-child {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 3px;
    }
    &:nth-child(2) {
      font-size: 16px;
      margin-bottom: 3px;
    }
    &:last-child {
      font-style: italic;
    }
  }
`;

const StudentsTable = ({
  filteredStudents,
  sessionAndStudentsData,
  setSessionAndStudentsData,
}) => {
  const [classStatusTypeUpdate, setClassStatusTypeUpdate] = useState({
    student_id: '',
    message: '',
    date_id: '',
  });
  const [changeClassStatusType] = useMutation(addAttendanceGQL, {
    onCompleted: ({ addAttendance: data }) => {
      setSessionAndStudentsData((prev) => ({
        session: prev.session,
        timeslots: prev.timeslots,
        students: prev.students.map(
          ({ student_id: sid, time_slot_taken: tst, ...rest }) => {
            if (sid === data.student_id && tst === data.time_slot_taken) {
              return {
                ...rest,
                student_id: sid,
                time_slot_taken: tst,
                class_status: data.class_status,
              };
            }

            return {
              student_id: sid,
              time_slot_taken: tst,
              ...rest,
            };
          }
        ),
      }));

      setClassStatusTypeUpdate((prev) => ({
        student_id: data.student_id,
        message: 'Updated.',
        date_id: prev.date_id,
      }));

      setTimeout(() => {
        setClassStatusTypeUpdate((prev) => ({
          student_id: data.student_id,
          message: '',
          date_id: prev.date_id,
        }));
      }, 1000);
    },
    onError: () => {
      setClassStatusTypeUpdate({ student_id: '', message: '', date_id: '' });
    },
  });

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <td>No.</td>
            <td>Timeslot</td>
            <td>Status</td>
            <td>Student</td>
            <td>Session Type</td>
            <td>Verified by</td>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map(
            (
              {
                student_id: sid,
                instructor_id: iid,
                name,
                email,
                verified,
                class_status: cs,
                time_slot_taken: tst,
                date_id: did,
              },
              index
            ) => (
              <tr key={`${did}_${sid}_${tst}_${index}}`}>
                <td
                  style={{
                    textAlign: 'center',
                    fontStyle: 'italic',
                  }}
                >{`${index + 1}.`}</td>
                <td>
                  {
                    sessionAndStudentsData.timeslots.filter(
                      ({ timeslot_id: tid }) => tid === tst
                    )[0].timeslot
                  }
                </td>
                <td>
                  {verified ? (
                    <VerifiedPill>Verified</VerifiedPill>
                  ) : (
                    <PendingPill>Pending</PendingPill>
                  )}
                </td>
                <td>
                  <StudentInfo>
                    <div>{sid}</div>
                    <div>{name}</div>
                    <div>{email}</div>
                  </StudentInfo>
                </td>
                <td>
                  <SessionTypeDropdown
                    onChange={(e) => {
                      const obj = {
                        date_id: did,
                        time_slot_taken: tst,
                        class_status: e.target.value,
                        student_id: sid,
                        verified,
                        instructor_id: iid,
                      };

                      setClassStatusTypeUpdate({
                        student_id: sid,
                        message: 'Updating...',
                        date_id: did,
                      });
                      changeClassStatusType({ variables: { params: obj } });
                    }}
                    status={cs}
                    defaultValue={cs}
                  >
                    <option value="online">Online</option>
                    <option value="offline">Offline</option>
                  </SessionTypeDropdown>

                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '13px',
                      lineHeight: '14px',
                      height: '14px',
                    }}
                  >
                    {classStatusTypeUpdate.student_id &&
                    classStatusTypeUpdate.message &&
                    classStatusTypeUpdate.date_id &&
                    classStatusTypeUpdate.student_id === sid &&
                    classStatusTypeUpdate.date_id === did
                      ? classStatusTypeUpdate.message
                      : ''}
                  </div>
                </td>
                <td>{verified ? iid : '-'}</td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    </Container>
  );
};

StudentsTable.defaultProps = {
  filteredStudents: [],
  sessionAndStudentsData: {},
  setSessionAndStudentsData: () => {},
};

StudentsTable.propTypes = {
  sessionAndStudentsData: PropTypes.shape({
    timeslots: PropTypes.arrayOf(PropTypes.string),
  }),
  setSessionAndStudentsData: PropTypes.func,
  filteredStudents: PropTypes.arrayOf(
    PropTypes.shape({
      student_id: PropTypes.string,
      instructor_id: PropTypes.string,
      date_id: PropTypes.string,
      verified: PropTypes.bool,
    })
  ),
};

export default StudentsTable;
