import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-bottom: 30px;
`;

const Label = styled.div`
  font-size: 16px;
  margin-right: 10px;
  width: 35%;
`;

const Dropdown = styled.select`
  border-radius: 10px;
  padding: 5px 10px;
  border: 1px solid ${$.color.gray};
  font-size: 16px;
  width: 65%;
  outline: none;
`;

const FilterStatus = ({ filterStatus, setFilterStatus }) => (
  <Container>
    <Label>Filter by status:</Label>
    <Dropdown
      value={filterStatus}
      onChange={(e) => {
        setFilterStatus(e.target.value);
      }}
    >
      <option value="all">All</option>
      <option value="verified">Verified</option>
      <option value="pending">Pending Verification</option>
    </Dropdown>
  </Container>
);

FilterStatus.defaultProps = {
  filterStatus: '',
  setFilterStatus: () => {},
};

FilterStatus.propTypes = {
  filterStatus: PropTypes.string,
  setFilterStatus: PropTypes.func,
};

export default FilterStatus;
