import { navigate } from 'gatsby';
import { useEffect, useState } from 'react';

import Utils from '../utils';
import Seo from '../components/Seo';
import VerifyStudentsForm from '../components/instructor/VerifyStudentsForm';

const VerifyPage = () => {
  const [group, setGroup] = useState(null);

  useEffect(() => {
    Utils.getGroupName().then((res) => {
      setGroup(res);
    });
  }, []);

  useEffect(() => {
    switch (group) {
      case Utils.STUDENTS_GROUP:
        navigate('/');
        break;
      default:
        break;
    }
  }, [group]);

  switch (group) {
    case Utils.ADMIN_GROUP:
    case Utils.INSTRUCTORS_GROUP:
      return (
        <>
          <Seo title="SGCC IMDA Attendance | Verify Students" />
          <VerifyStudentsForm />
        </>
      );
    default:
      return <div />;
  }
};

export default VerifyPage;
