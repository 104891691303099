import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';

const Container = styled.div`
  border: 1px solid ${$.color.gray};
  border-radius: 10px;
  padding: 20px 10px 10px 10px;
  margin-bottom: 20px;
  position: relative;
`;

const Title = styled.div`
  position: absolute;
  top: -10px;
  background-color: ${$.color.white};
  padding: 0 10px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  flex-wrap: wrap;
`;

const TimeslotButton = styled.div`
  margin-bottom: 10px;
  border: 2px solid ${$.color.orange4};
  color: ${$.color.orange4};
  padding: 5px 15px;
  border-radius: 10px;
  &:not(:last-child) {
    margin-right: 10px;
  }

  &.selected {
    background-color: ${$.color.orange4};
    color: ${$.color.white};
  }

  &:hover {
    cursor: pointer;
  }
`;

const FilterTimeslot = ({
  sessionAndStudentsData,
  selectedTimeslots,
  setSelectedTimeslots,
}) => (
  <Container>
    <Title>Filter by timeslots:</Title>
    <InnerContainer>
      {sessionAndStudentsData.session.timeslots.map((session) => {
        const slot = sessionAndStudentsData.timeslots.filter(
          ({ timeslot_id: tid }) => session === tid
        )[0];

        return (
          <TimeslotButton
            key={`session_${session}`}
            className={
              selectedTimeslots.indexOf(session) > -1 ? 'selected' : ''
            }
            onClick={() => {
              setSelectedTimeslots((prev) => {
                const newPrev = [...prev];
                const index = newPrev.indexOf(session);

                if (index > -1) {
                  newPrev.splice(index, 1);
                } else {
                  newPrev.push(session);
                }

                return newPrev;
              });
            }}
          >
            {slot.timeslot}
          </TimeslotButton>
        );
      })}
    </InnerContainer>
  </Container>
);

FilterTimeslot.defaultProps = {
  sessionAndStudentsData: {
    session: {},
    students: [],
    timeslots: [],
  },
  selectedTimeslots: [],
  setSelectedTimeslots: () => {},
};

FilterTimeslot.propTypes = {
  sessionAndStudentsData: PropTypes.shape({
    session: PropTypes.oneOfType([PropTypes.object]),
    students: PropTypes.oneOfType([PropTypes.array]),
    timeslots: PropTypes.oneOfType([PropTypes.array]),
  }),
  selectedTimeslots: PropTypes.arrayOf(PropTypes.string),
  setSelectedTimeslots: PropTypes.func,
};

export default FilterTimeslot;
