import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FilterTimeslot from './FilterTimeslot';
import FilterStatus from './FilterStatus';
import $ from '../../../styles/global';

const Container = styled.div`
  border-bottom: 1px solid ${$.color.gray};
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const TotalVerified = styled.div`
  font-size: 28px;
  font-weight: bold;
`;

const Info = ({
  sessionAndStudentsData,
  selectedTimeslots,
  setSelectedTimeslots,
  filterStatus,
  setFilterStatus,
}) => {
  const [verifiedTotal, setVerifiedTotal] = useState(0);
  const getDate = () => {
    const splitDate = sessionAndStudentsData.session.date_id.split('_') || [];

    return splitDate.length > 0
      ? `${splitDate[1]} ${splitDate[0]} '${splitDate[2].substr(2, 4)}`
      : '';
  };

  useEffect(() => {
    setVerifiedTotal(
      () =>
        sessionAndStudentsData.students.filter(({ verified }) => verified)
          .length
    );
  }, [sessionAndStudentsData.students]);

  return (
    <Container>
      <Title>{`Class: ${getDate()}`}</Title>
      <FilterTimeslot
        sessionAndStudentsData={sessionAndStudentsData}
        selectedTimeslots={selectedTimeslots}
        setSelectedTimeslots={setSelectedTimeslots}
      />
      <FilterStatus
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
      />
      <TotalVerified>{`${verifiedTotal}/${sessionAndStudentsData.students.length} verified`}</TotalVerified>
    </Container>
  );
};

Info.defaultProps = {
  sessionAndStudentsData: {
    session: {},
    students: [],
    timeslots: [],
  },
  selectedTimeslots: [],
  setSelectedTimeslots: () => {},
  filterStatus: '',
  setFilterStatus: () => {},
};

Info.propTypes = {
  sessionAndStudentsData: PropTypes.shape({
    session: PropTypes.oneOfType([PropTypes.object]),
    students: PropTypes.oneOfType([PropTypes.array]),
    timeslots: PropTypes.oneOfType([PropTypes.array]),
  }),
  selectedTimeslots: PropTypes.arrayOf(PropTypes.string),
  setSelectedTimeslots: PropTypes.func,
  filterStatus: PropTypes.string,
  setFilterStatus: PropTypes.func,
};

export default Info;
