/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import $ from '../../../styles/global';
import { getSessionAndStudentsGQL } from '../../../apollo/queries';
import { verifyAttendancesGQL } from '../../../apollo/mutations';
import Info from './Info';
import Search from './Search';
import StudentsTable from './StudentsTable';
import EditIcon from '../../../assets/icons/edit.svg';

const Section = styled.section``;

const Container = styled.div`
  padding: 50px 0;
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;

  & > * {
    margin-bottom: 10px;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const VerifyAllButton = styled.button`
  ${({ verify, noClick }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 120px;
  border: 1px solid transparent;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: ${
    noClick ? $.color.gray : verify ? $.color.green : '#a6a602'
  };
  ${noClick ? 'pointer-events: none;' : ''}

  & > svg {
    fill: ${noClick ? $.color.black : verify ? $.color.white : $.color.black};
    width: 20px;
    height: 20px;
    margin-right: ${$.layout().margin5}px;
  }

  & > span {
    font-size: 16px;
    font-weight: bold;
    ${noClick ? 'font-style: italic;' : ''}
    color: ${noClick ? $.color.black : verify ? $.color.white : $.color.black};
  }

  &:hover {
    cursor: pointer;
    background-color: ${$.color.white};
    border: 1px solid ${verify ? $.color.green : '#a6a602'};

    & > span {
      color: ${verify ? $.color.green : '#a6a602'};
    }

    & > svg {
      fill: ${verify ? $.color.green : '#a6a602'};
    }
  }
`}
`;

const VerifyStudentsForm = () => {
  const [dateId, setDateId] = useState('');
  const [sessionAndStudentsData, setSessionAndStudentsData] = useState({});
  const [filterStatus, setFilterStatus] = useState('all');
  const [selectedTimeslots, setSelectedTimeslots] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [verifyStudents, setVerifyStudents] = useState(false);
  const [disableVerifyButton, setDisableVerifyButton] = useState(false);
  const [getSessionAndStudents] = useLazyQuery(getSessionAndStudentsGQL, {
    onCompleted: ({ getSessionAndStudents: data }) => {
      setSessionAndStudentsData(data);
    },
  });
  const [verifyAttendances] = useMutation(verifyAttendancesGQL, {
    onCompleted: ({ verifyAttendances: data }) => {
      const updatedStudents = sessionAndStudentsData.students.map(
        ({ student_id: sid, date_id: did, ...rest }) => {
          const updatedStudent = data.filter(
            ({ student_id: sidFound, date_id: dateFound }) =>
              sidFound === sid && dateFound === did
          )[0];

          return {
            ...rest,
            student_id: sid,
            date_id: did,
            instructor_id: updatedStudent
              ? updatedStudent.instructor_id
              : rest.instructor_id,
            verified: updatedStudent ? updatedStudent.verified : rest.verified,
          };
        }
      );

      setDisableVerifyButton(false);
      setSessionAndStudentsData((prev) => ({
        session: prev.session,
        timeslots: prev.timeslots,
        students: updatedStudents,
      }));
    },
    onError: () => {
      setDisableVerifyButton(false);
    },
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search);
      const dateIdQueryString = searchParams.get('date_id');

      if (dateIdQueryString) {
        setDateId(dateIdQueryString);
      }
    }
  }, []);

  useEffect(() => {
    if (dateId) {
      getSessionAndStudents({ variables: { date_id: dateId } });
    }
  }, [dateId]);

  useEffect(() => {
    const results =
      sessionAndStudentsData?.students
        ?.filter(({ verified }) => {
          switch (filterStatus) {
            case 'all':
              return true;
            case 'pending':
              return verified === null || verified === false;
            default:
              return verified;
          }
        })
        .filter(({ name, email, student_id: sid, instructor_id: iid }) => {
          const st = searchTerm.toLowerCase();

          return (
            (name || '').toLowerCase().indexOf(st) > -1 ||
            sid.toLowerCase().indexOf(st) > -1 ||
            (iid || '').toLowerCase().indexOf(st) > -1 ||
            email.toLowerCase().indexOf(st) > -1
          );
        })
        .filter(
          ({ time_slot_taken: tst }) =>
            selectedTimeslots.length === 0 ||
            selectedTimeslots.indexOf(tst) > -1
        ) || [];

    setFilteredStudents(results);
  }, [sessionAndStudentsData, filterStatus, selectedTimeslots, searchTerm]);

  useEffect(() => {
    const totalVerified =
      filteredStudents.filter(({ verified }) => verified).length || 0;

    setVerifyStudents(totalVerified < filteredStudents.length - totalVerified);
  }, [filteredStudents]);

  return (
    <Section>
      <Container>
        {sessionAndStudentsData?.session ? (
          <Info
            sessionAndStudentsData={sessionAndStudentsData}
            selectedTimeslots={selectedTimeslots}
            setSelectedTimeslots={setSelectedTimeslots}
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
          />
        ) : (
          'Loading...'
        )}
        <Toolbar>
          <Search setSearchTerm={setSearchTerm} />
          {filteredStudents.length > 0 && (
            <VerifyAllButton
              noClick={disableVerifyButton}
              verify={verifyStudents}
              onClick={() => {
                setDisableVerifyButton(true);
                verifyAttendances({
                  variables: {
                    students: filteredStudents.map(
                      ({
                        student_id: sid,
                        date_id: did,
                        class_status: cs,
                        time_slot_taken: tst,
                      }) => ({
                        student_id: sid,
                        date_id: did,
                        class_status: cs,
                        time_slot_taken: tst,
                        verified: verifyStudents,
                      })
                    ),
                  },
                });
              }}
            >
              <EditIcon />
              <span>
                {disableVerifyButton
                  ? 'Updating...'
                  : `Set ${filteredStudents.length}
              students(s) ${verifyStudents ? 'to VERIFIED' : 'to PENDING'}`}
              </span>
            </VerifyAllButton>
          )}
        </Toolbar>
        <StudentsTable
          filteredStudents={filteredStudents}
          sessionAndStudentsData={sessionAndStudentsData}
          setSessionAndStudentsData={setSessionAndStudentsData}
          dateId={dateId}
        />
      </Container>
    </Section>
  );
};

export default VerifyStudentsForm;
